import { Breadcrumb } from '@/Models'
import BreadCrumbModule from '@/store/moduleBreadCrumb'
import { Component, Vue } from 'vue-property-decorator'
import { mapMutations } from 'vuex'

// import BreadCrumb from '@/components/BreadCrumbs/BreadCrumbs.vue'

@Component({
  components: {},
})
export default class BreadCrumb extends Vue {
  bTooltip: any
  BTooltip: any
  bBreadcrumb: any
  BBreadcrumb: any
  bBreadcrumbItem: any
  BBreadcrumbItem: any
  breadcrumb: any
  mounted() {
    // this.collectionName = BreadCrumbModule.collect_name as string
  }

  get breadcrumbList() {
    return BreadCrumbModule.breadcrumbList
  }
  toRoute(BreadCrumb: Breadcrumb) {
    this.$router.push(BreadCrumb.link)
    BreadCrumbModule.remove(BreadCrumb.link)
  }
}
